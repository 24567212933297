// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "v_rm d_bz";
export var storyRowWrapper = "v_hv d_hv d_bG";
export var storyLeftWrapper = "v_rn d_bw d_bL";
export var storyWrapperFull = "v_rp d_cz";
export var storyWrapperFullLeft = "v_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "v_mC d_hw";
export var storyLeftWrapperCenter = "v_rq d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "v_rr d_hC";
export var storyHeader = "v_rs d_hB d_s d_cp";
export var storyHeaderCenter = "v_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "v_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "v_rt d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "v_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "v_rv d_fc d_W";
export var imageWrapperFull = "v_rw d_s d_D d_bb d_W";