// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_s2";
export var lbContainerInner = "C_s3";
export var movingForwards = "C_s4";
export var movingForwardsOther = "C_s5";
export var movingBackwards = "C_s6";
export var movingBackwardsOther = "C_s7";
export var lbImage = "C_s8";
export var lbOtherImage = "C_s9";
export var prevButton = "C_tb";
export var nextButton = "C_tc";
export var closing = "C_td";
export var appear = "C_tf";