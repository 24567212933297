// extracted by mini-css-extract-plugin
export var alignLeft = "n_p5 d_fl d_bC d_dr";
export var alignCenter = "n_bL d_fm d_bz d_ds";
export var alignRight = "n_p6 d_fn d_bD d_dt";
export var comparisonContainer = "n_p7 d_dT";
export var comparisonContainerFull = "n_p8 d_dR";
export var comparisonFloatyBox = "n_hZ d_hZ d_bv d_W d_cw";
export var comparisonSubtitle = "n_h8 d_h8 d_s d_c2";
export var comparisonRow = "n_h4 d_h4 d_b8 d_bF";
export var comparisonMainHeader = "n_h5 d_h5 d_s d_cs";
export var comparisonComponentText = "n_h1 d_h1 d_s";
export var comparisonBtnWrapper = "n_jg d_jg d_dY d_s d_bw";
export var comparisonBtnWrapperSecond = "n_jh d_jh d_s";
export var comparisonImageContainer = "n_jd d_jd d_W d_bv d_s";
export var stretch = "n_p9";
export var limit = "n_qb";
export var exceptionWeight = "n_qc x_sd";