// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_qY d_gs d_cp";
export var heroHeaderCenter = "r_gt d_gt d_cp d_ds";
export var heroHeaderRight = "r_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "r_qZ d_gp d_cs";
export var heroParagraphCenter = "r_gq d_gq d_cs d_ds";
export var heroParagraphRight = "r_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "r_q0 d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "r_q1 d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "r_q2 d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "r_q3 d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "r_q4 d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "r_q5 x_q5";
export var heroExceptionNormal = "r_q6 x_q6";
export var heroExceptionLarge = "r_q7 x_q7";
export var Title1Small = "r_q8 x_q8 x_rN x_rP";
export var Title1Normal = "r_q9 x_q9 x_rN x_rQ";
export var Title1Large = "r_rb x_rb x_rN x_rR";
export var BodySmall = "r_rc x_rc x_rN x_r5";
export var BodyNormal = "r_rd x_rd x_rN x_r6";
export var BodyLarge = "r_rf x_rf x_rN x_r7";